










































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/common/Modal.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment')

@Component({
  components: {
    Modal,
    PrimaryButton
  }
})
export default class OutCome extends Vue {
  @Prop({ type: String, required: true, default: '' })
  private img!: string

  @Prop({ type: String, required: true, default: '' })
  private title!: string

  @Prop({ type: String, required: false, default: '' })
  private description!: string

  @Prop({ type: String, required: false, default: '' })
  private descriptionTitle!: string

  @Prop({ type: String, required: true, default: '' })
  private btnTextRateUs!: string

  @Prop({ type: String, required: true, default: '' })
  private btnTextTakeAnotherTest!: string

  @Prop({ type: Boolean, required: false, default: false })
  private isTime!:boolean

  @Prop({ type: Boolean, required: false, default: false })
  private restartAction!:boolean

  @Prop({ type: Boolean, required: false, default: false })
  private rateUs!:boolean

  private goTo (): void {
    this.$router.push({ name: 'splash' })
  }

  private isModal = false

  private mounted (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.$router.push({ name: 'demographic welcome' })
      })
    }
  }

  private destroyed (): void {
    if (window.innerWidth < 767) this.$root.$off('mobile-go-back')
  }

  get completeTime (): string {
    const date = this.$store.state.walkthrough.test_completion_time
    return moment(date).format('h:mm A') || ''
  }

  get expiredTime (): string {
    const date = this.$store.state.walkthrough.test_validity_expiration_time
    return moment(date).format('MM/DD/YYYY') || ''
  }

  private restart (): void {
    this.$store.dispatch('walkthrough/setHaveFreeTime', false)
      .then(() => {
        sessionStorage.clear()
        this.$router.push({ name: 'demographic welcome' })
      })
    this.$store.dispatch('walkthrough/setHaveFreeTime', false)
    this.$store.dispatch('auth/setDemographic', false)
    this.$store.dispatch('walkthrough/setDeviceCompatible', '')
    this.$store.dispatch('walkthrough/setPassport', '')
  }
}
